import React, { useState } from "react";
// import { useTranslation } from "react-i18next";
// import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";

const useInfoPopup = () => {
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const [openPopupText, setOpenPopupText] = useState<string>("");

  const handleOpenPopup = (text: string) => {
    setOpenPopupText(text);
    setOpenPopup(true);
  };

  const closePopup = () => {
    setOpenPopup(false);
  };

  return {
    openPopup,
    closePopup,
    handleOpenPopup,
  };
};

interface InfoPopupProps {
  open: boolean;
  onClose: () => void;
  openPopupText: string;
}

const InfoPopup: React.FC<InfoPopupProps> = ({
  open,
  onClose,
  openPopupText,
}) => {


  const handleClose = () => {
    onClose();
  };

  return (
    <div className={`info-popup ${open ? "open" : ""}`}>
      <div className="popup-header">
        <h3>{openPopupText}</h3>
        {/* <button className="close-btn" onClick={handleClose}>
          Close icon
        </button> */}
      </div>
      <div className="popup-content">
        <p>{openPopupText}</p>
      </div>
    </div>
  );
};

export { useInfoPopup, InfoPopup };
