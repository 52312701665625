import React from "react";
import TitleSection from "../TitleSection/TitleSection";
import { IPwaProps } from "../../interfaces";
import "./styles.scss";
import { textsToTranslate } from '../../utils/translationServise';

const Description: React.FC<IPwaProps> = ({ pwa }) => {
  const translations = pwa.translations || textsToTranslate;
  return (
    <div className="description-container">
      <TitleSection title={translations?.about} />
      <div className="description-content">
        <div className="description-text">
          <p>{pwa.description}</p>
        </div>
        <div className="description-category">
          {pwa.category.map((category, index) => (
            <span key={category + index} className="category-badge">{category}</span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Description;
