import React, { useEffect } from "react";
import { IPwaProps } from "../../interfaces";
import "./styles.scss";
import placeholder from "../../assets/pwa-vertical-placeholder.jpg";

const Vertical: React.FC<IPwaProps> = ( { pwa } ) => {
  // const previews = pwa?.creatives?.previews && pwa?.creatives?.previews?.length ? pwa?.creatives?.previews : [
  //   placeholder, placeholder, placeholder
  // ];
  const previews = pwa?.creatives?.previews && pwa?.creatives?.previews?.length ? pwa?.creatives?.previews : [];
  // console.log( 'previews', previews )
  useEffect( () => {
    const images = document.querySelectorAll( ".vertical-box img" );
    images.forEach( ( image: any ) => {
      image.onload = checkImageOrientation;
      if( image.complete ) {
        console.log( "complete" );
        checkImageOrientation( { target: image } );
      }
    } );
  }, [previews] );

  return previews?.length ? (
    <div className="vertical">
      <div className="vertical-inner">
        { previews?.map( ( preview, index ) => {
          return (
            <div className="vertical-box" key={ `preview-${ index }` }>
              <img src={ preview } loading="lazy" onLoad={ checkImageOrientation } alt={ `app screenshot ${ index }` }/>
            </div>
          );
        } ) }
      </div>
    </div>
  ) : null;
};

const checkImageOrientation = ( e: any ) => {
  const image = e.target;
  const isVertical = image.height > image.width;
  const parent = image.parentElement;
  parent.style.aspectRatio = image.width / image.height;

  if( isVertical ) {
    parent.classList.add( "vertical-item" );
    parent.classList.remove( "horizontal-item" );
  } else {
    parent.classList.add( "horizontal-item" );
    parent.classList.remove( "vertical-item" );
  }
};

export default Vertical;
