import React from "react";
import TitleSection from "../TitleSection/TitleSection";
import BackupIcon from "../../assets/icons/Backup";
import Share from "../../assets/icons/Share";
import HttpsIcon from "../../assets/icons/Https";
import { IPwaProps } from "../../interfaces";
import "./styles.scss";
import { textsToTranslate } from '../../utils/translationServise';

const DataSecurity: React.FC<IPwaProps> = ({ pwa }) => {
    const translations = pwa.translations || textsToTranslate;
  return (
    <div className="security">
      <div className="security__title-section">
        <TitleSection title={translations?.dataSafety} />
      </div>

      <div className="security__text">{translations?.dataSafetyText}</div>

      <div className="security__box">
        <div className="icon-line">
          <Share />
          <div style={{ paddingLeft: "10px" }}>
            <div className="security__box-text">{translations?.dataShare}</div>
            <div className="security__box-subtext">
              {translations?.location}
            </div>
          </div>
        </div>

        <div className="icon-line">
          <BackupIcon />
          <div style={{ paddingLeft: "10px" }}>
            <div className="security__box-text">
              {translations?.dataCollect}
            </div>
            <div className="security__box-subtext">
              {translations?.location}
            </div>
          </div>
        </div>

        <div className="icon-line">
          <HttpsIcon />
          <div style={{ paddingLeft: "10px" }}>
            <div className="security__box-text">
              {translations?.dataEncripted}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataSecurity;
