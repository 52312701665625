import React from "react";
import TitleSection from "../TitleSection/TitleSection";
import Globe from "../../assets/icons/Globe";
import Email from "../../assets/icons/Email";
import MapPin from "../../assets/icons/MapPin";
import Shield from "../../assets/icons/Shield";
import { IPwaProps } from "../../interfaces";
import "./styles.scss";
import { textsToTranslate } from '../../utils/translationServise';

const ContactDeveloper: React.FC<IPwaProps> = ({ pwa }) => {
    const translations = pwa.translations || textsToTranslate;
  return (
    <div>
      <TitleSection title={translations?.support} />

      <div className="contact-developer no-padding">
        <a className="link" href={pwa?.developerSite}>
          <Globe />
          <div className="contact-developer__item">
            <span className="text">{translations?.website}</span>
          </div>
        </a>
      </div>

      <div className="contact-developer">
        <Email />
        <div className="contact-developer__item">
          <div className="text">{translations?.email}</div>
          <span className="sub-text">{pwa?.developerEmail}</span>
        </div>
      </div>

      <div className="contact-developer">
        <MapPin />
        <div className="contact-developer__item">
          <div className="text">{translations?.adress}</div>
          <span className="sub-text">{pwa?.adressCompany}</span>
        </div>
      </div>

      <div className="contact-developer">
        <a href="#" className="link">
          <Shield />
        </a>
        <div className="contact-developer__item">
          <span className="text">{translations?.privacy}</span>
        </div>
      </div>
    </div>
  );
};

export default ContactDeveloper;
