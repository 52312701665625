interface keyVal {
  [key: string]: string | number;
}

const API_DEEPL_KEY = "432b7dff-2266-4a13-9e54-36294d2ed711:fx";

export const textsToTranslate: any = {
  productivity: "Productivity",
  downloads: "Downloads",
  ratedFor: "Rated for",
  reviews: "Reviews",
  age: "Age",
  installations: "Installations",
  install: "Install",
  support: "App support",
  website: "Website",
  email: "E-mail",
  adress: "Adress",
  privacy: "Privacy policy",
  about: "About app",
  description: "Description",
  ratings: "Ratings and reviews",
  language: "Language",
  openBtn: "Open",
  installBtn: "Install",
  dataSafety: "Data safety",
  dataSafetyText:
    "Security is determined by how the app collects and shares your data. How the app protects your privacy and data may vary depending on how you use the app, your region, and your age. The developer who provides this information may update it.",
  dataShare: "This app may share these data types with third parties",
  dataCollect: "This app may collect these data types",
  location: "Location, Personal info, and App activity",
  dataEncripted: "Data is encrypted in transit",
  peoplefoundthishelpful: "people found this helpful",
  errorInstall: "error on Install",
  containsAds: "contains ads",
  inAppPurchases: "in-app purchases",
  cookeTitle: "Do you agree to the use of cookies?",
  agree: "Agree",
  disagree: "Disagree",
};

const getCachedTranslations = (texts: keyVal, targetLanguage: string) => {
  const cachedTranslations =
    JSON.parse(localStorage.getItem("translations") ?? "{}") ||
    textsToTranslate;
  const translations: any = {};
  let allCached = true;

  Object.keys(texts).forEach((key) => {
    const cached =
      cachedTranslations[key] && cachedTranslations[key][targetLanguage]
        ? cachedTranslations[key][targetLanguage]
        : null;
    translations[key] = cached || texts[key];
    if (!cached) allCached = false;
  });

  return { translations, allCached };
};

const cacheTranslations = (translations: keyVal, targetLanguage: string) => {
  const cachedTranslations =
    JSON.parse(localStorage.getItem("translations") || "{}") ||
    textsToTranslate;

  Object.keys(translations).forEach((key) => {
    if (!cachedTranslations[key]) cachedTranslations[key] = {};
    cachedTranslations[key][targetLanguage] = translations[key];
  });

  localStorage.setItem("translations", JSON.stringify(cachedTranslations));
};

export const translateText = async (
  texts: any,
  targetLanguage = "en-GB",
  domain: string
) => {
  const { translations, allCached } = getCachedTranslations(
    texts,
    targetLanguage
  );

  if (allCached) {
    return translations;
  }

  const translationsArray = Object.values(translations);

  const textsToTranslate = Object.values(texts).filter((text, key) => {
    return translationsArray[key as keyof typeof translationsArray] === text;
  });

  try {
    const response = await fetch(`${domain}/api/deepl`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `DeepL-Auth-Key ${API_DEEPL_KEY}`,
      },
      body: JSON.stringify({
        text: textsToTranslate.join("\n"),
        target_lang: targetLanguage,
      }),
    });

    if (!response.ok) {
      throw new Error("Failed to fetch translations");
    }

    const data = await response.json();
    const translatedTexts = data.text?.split("\n");

    Object.keys(texts).forEach((key, index) => {
      if (translationsArray[index] === texts[key]) {
        translations[key] = translatedTexts.shift();
      }
    });

    // Кэшируем переводы
    cacheTranslations(translations, targetLanguage);

    return translations;
  } catch (error) {
    console.error("Translation API error:", error);
    // В случае ошибки возвращаем английские тексты по умолчанию
    return textsToTranslate;
  }
};
