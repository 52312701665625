import React, { useEffect } from "react";
import { IPwaProps } from "../../interfaces";
import { getProgressValue } from "../../utils/helpers";
import TitleSection from "../TitleSection/TitleSection";
import Comments from "../Comments/Comments";
import "./styles.scss";
import Star from "../../assets/icons/Star";
import { textsToTranslate } from '../../utils/translationServise';

const Rating: React.FC<IPwaProps> = ({ pwa }) => {
  const ratingValue = Math.floor(Number(pwa?.rating));
  const maxRating = 5;
    const translations = pwa.translations || textsToTranslate;
  return (
    <>
      <div>
        <TitleSection title={translations?.ratings} />
      </div>

      <div className="ratingContainer">
        <div style={{ paddingRight: "24px" }}>
          <div className="ratingNumber">{pwa?.rating}</div>

          <div className="stars">
            {Array.from({ length: maxRating }, (_, index) => (
              <Star
                key={index}
                fill={index < ratingValue ? "#01875f" : "#E0E0E0"}
              />
            ))}
          </div>

          <div className="reviewCount">{`${pwa?.amountReviews} `}</div>
        </div>

        <div className="progressBarContainer">
          {pwa.ratings?.map((item, index) => (
            <div className="ratingBarContainer" key={index}>
              <div className="ratingBarText">{5 - index}</div>
              <div className="progressBarContainer">
                <div className="progressBar">
                  <div
                    className="progressFill"
                    style={{
                      width: `${getProgressValue(item, pwa?.amountReviews)}%`,
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="commentsContainer">
        <Comments  pwa={pwa} />
      </div>
    </>
  );
};

export default Rating;
