import React, { useEffect, useRef, useState } from "react";
import "./styles.scss";
import { InfoPopup, useInfoPopup } from "../InfoPopup/InfoPopup";
import { ITextsToTranslate } from "../../interfaces";

// import { updateOfferLinkWithGetParams } from "../../utils/updateOfferLinkWithGetParams";

interface InstallButtonProps {
  translations: ITextsToTranslate;
  defferedPrompt: any;
  setDefferedPrompt: any;
  testPwaDomain?: string;
  pwaData: any;
}

const InstallButton: React.FC<InstallButtonProps> = ( {
  translations,
  defferedPrompt,
  setDefferedPrompt,
  testPwaDomain,
  pwaData,
} ) => {
  const [loading, setLoading] = useState( false );
  const domain = window.location.hostname;
  const installButton = useRef( null );
  const isInstalledStorage = localStorage.getItem( "pwaLastInstall" );
  const [isInstalled, setIsInstalled] = useState( Boolean( isInstalledStorage ) );

  const isSafari = /^((?!chrome|android).)*safari/i.test( navigator.userAgent );
  //@ts-ignore
  const isIOS = /iPad|iPhone|iPod/.test( navigator.userAgent ) && !window.MSStream;
  // useEffect( () => {
  //   if( !defferedPrompt ) {
  //     setIsInstalled( true );
  //   }
  // }, [defferedPrompt] );

  const isWeb = true;

  const { openPopup, closePopup, handleOpenPopup } = useInfoPopup();

  const saveInstall = async() => {
    const requestDomain = testPwaDomain || domain;
    if( requestDomain ) {
      try {
        const response = await fetch( `https://${ requestDomain }/api/v1/pwa/current/install` );
        if( !response.ok ) {
          throw new Error( "Ошибка при загрузке данных" );
        }
      } catch( error ) {
        console.error( "error", error );
        setLoading( false );
      }
    }
  };

  const continueToInstall = () => {
    const fbq = (window as any).fbq || function() {
    };
    fbq( 'track', 'Lead' );
    void saveInstall();
    //@ts-ignore
    localStorage.setItem( "pwaLastInstall", Date.now() );
    setIsInstalled( true );
  }

  const handleClick = () => {
    setLoading( true );
    const combinedQueryString = mergeQueryStrings( pwaData.offerLink, window.location.search );
    const finalLink = `${ pwaData?.blackLink }${ combinedQueryString.length ? '?' + combinedQueryString : '' }`

    if( isInstalled ) {
      window.location.href = finalLink;
      return;
    }

    setTimeout( () => {
      setLoading( false );

      if( !isSafari && !isIOS ) {
        continueToInstall();
      }

      if( defferedPrompt ) {
        try {
          defferedPrompt.prompt();
          defferedPrompt.userChoice
            .then( ( choiceResult: any ) => {
              if( choiceResult.outcome === "accepted" ) {
                continueToInstall();
                setDefferedPrompt( null );
              } else {
                setDefferedPrompt( null );
              }
            } )
            .catch( ( err: any ) => {
              console.log( err );
              handleOpenPopup( "Error install" );
            } );
        } catch( error ) {
          console.error( "Error:", error );
        }
      } else {
        setIsInstalled( true );
      }
    }, 2000 );
  };

  return (
    <div className="install-button-container">
      <InfoPopup open={ openPopup } onClose={ closePopup } openPopupText=""/>
      { isWeb ? (
        <div className="install-button-web">
          <button
            className={ `color-button-web ${ loading ? "loading" : "" }` }
            onClick={ handleClick }
            disabled={ loading }
            ref={ installButton }
          >
            { loading ? (
              <div className="circular-progress invert"/>
            ) : isInstalled ? (
              translations.openBtn
            ) : (
              translations.install
            ) }
          </button>
          <div className="share-icon-container">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="#01875f">
              <path
                d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"></path>
            </svg>
          </div>
          <div className="bookmark-icon-container">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="#01875f">
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7 3H17C18.1045 3 19 3.8955 19 5V21L12 18L5 21L5.01075 5C5.01075 3.8955 5.8965 3 7 3ZM12 15.824L17 18V5H7V18L12 15.824ZM13 7V9H15V11H13V13H11V11H9V9H11V7H13Z"
              ></path>
            </svg>
          </div>
        </div>
      ) : (
        <button
          className={ `color-button-mobile ${ loading ? "loading" : "" }` }
          onClick={ handleClick }
          disabled={ loading }
          ref={ installButton }
        >
          { loading ? (
            <div className="circular-progress invert"/>
          ) : isInstalled ? (
            translations.openBtn
          ) : (
            translations.install
          ) }
        </button>
      ) }
      { loading && (
        <div className="backdrop">
          <div className="circular-progress"/>
        </div>
      ) }
    </div>
  );
};

function parseQueryString( queryString: string ) {
  const query = queryString.includes( '?' ) ? queryString.split( '?' )[1] : queryString;
  const params = new URLSearchParams( query );
  const result: { [key: string]: string } = {};

  params.forEach( ( value, key ) => {
    result[key] = value;
  } );

  return result;
}

function mergeQueryStrings( queryString1: string, queryString2: string ) {
  const params1 = parseQueryString( queryString1 );
  const params2 = parseQueryString( queryString2 );

  const mergedParams = { ...params1, ...params2 };

  const urlSearchParams = new URLSearchParams( mergedParams );
  return urlSearchParams.toString();
}

export default InstallButton;
