import React from "react";

interface StarProps {
  fill?: string;
}

function Star({ fill = "#000" }: StarProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="20px"
      viewBox="0 -960 960 960"
      width="20px"
      fill={fill}
    >
      <path d="m231.04-127.5 66.35-279.63L76.63-595.46l289.87-24.95L480-884.33l113.5 264.92 289.87 23.95-220.76 188.33 66.35 279.63L480-275.91 231.04-127.5Z" />
    </svg>
  );
}

export default Star;
