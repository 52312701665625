import React from "react";

export default function Arrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="20px"
      viewBox="0 -960 960 960"
      width="20px"
      fill="#000"
    >
      <path d="m330-444 201 201-51 51-288-288 288-288 51 51-201 201h438v72H330Z" />
    </svg>
  );
}
