import React from "react";
import arrowBackIcon from "../../assets/icons/arrow_back.png";
import "./styles.scss";
import ArrowUp from "../../assets/icons/ArrowUp";

interface ITitleSection {
  title: any;
}

const TitleSection: React.FC<ITitleSection> = ({ title }) => {
  const isMobile = window.innerWidth <= 600;

  return (
    <div className="title-section">
      <div className="title">
        <p>{title}</p>
      </div>
      <div className={`icon ${isMobile ? "icon-mobile" : ""}`}>
        <ArrowUp />
      </div>
    </div>
  );
};

export default TitleSection;



