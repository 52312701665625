export const formatRating = (rating: string) => {
  const numericRating = parseFloat(rating);
  return numericRating === 5
    ? numericRating.toFixed(0)
    : numericRating.toFixed(2);
};

export const formatReviews = (amountReviews: string) => {
  const reviewsNumber = Number(amountReviews);
  if (reviewsNumber >= 1000) {
    return `${Math.floor(reviewsNumber / 1000)}K`;
  }
  return amountReviews;
};

export const generateRandomNumber = () => {
  return Math.floor(Math.random() * (3000 - 700 + 1)) + 700;
};

export function formatDate(dateString: string | number | Date): string {
  const date = new Date(dateString);

  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Месяцы начинаются с 0, поэтому добавляем 1
  const year = date.getFullYear();

  return `${day}/${month}/${year}`;
}

export const getProgressValue = (stars: number, amountReviews: string) => {
  return (stars / Number(amountReviews)) * 100;
};
