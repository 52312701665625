import React, { useState } from "react";
import { IComment, IPwaProps, ITextsToTranslate } from "../../interfaces";
import { formatDate, generateRandomNumber } from "../../utils/helpers";
import "./styles.scss";
import Dot from "../../assets/icons/Dot";
import Star from "../../assets/icons/Star";
import { textsToTranslate } from "../../utils/translationServise";

const Comments: React.FC<IPwaProps> = ({ pwa }) => {
  const translations = pwa.translations || textsToTranslate;
  const comments = pwa?.comments || [];
  return (
    <div>
      {comments.length > 0 ? (
        comments.map((comment: IComment, index) => (
          <div className="commentContainer" key={index}>
            <div className="commentHeader">
              <div className="commentAuthor">
                {comment?.avatar ? (
                  <img className="commentAvatar" src={comment?.avatar} alt={comment?.author} width={36} height={36} />
                ) : (
                  <img className="commentAvatar" src={"/avatar.png"} alt={comment?.author} width={36} height={36} />
                )}

                <span className="commentAuthorName">{comment?.author}</span>
              </div>
              <div>
                <Dot />
              </div>
            </div>

            <div className="commentRating">
              <div className="ratingStars">
                {Array.from({ length: 5 }, (_, index) => (
                  <Star key={index} fill={index < Math.floor(comment?.rating) ? "#01875f" : "#E0E0E0"} />
                ))}
              </div>
              <span className="commentDate">{formatDate(comment?.date)}</span>
            </div>

            <div>
              <p className="commentText">{comment?.comment}</p>
            </div>

            <div>
              <span className="commentHelpful">
                {generateRandomNumber()} {translations?.peoplefoundthishelpful}
              </span>
            </div>
          </div>
        ))
      ) : (
        <p>{"No comments available."}</p>
      )}

      {/* {!showAll && comments.length > 3 && (
       <div className="showAllContainer">
       <button className="showAllButton" onClick={handleShowAll}>
       {translations?.peoplefoundthishelpful}
       </button>
       </div>
       )} */}
    </div>
  );
};

export default Comments;
