import React, { useEffect, useRef } from "react";
import MainInfo from "./components/MainInfo/MainInfo";
import Vertical from "./components/Vertical/Vertical";
import Description from "./components/Description/Description";
import DataSecurity from "./components/DataSecurity/DataSecurity";
import Rating from "./components/Rating/Rating";
import ContactDeveloper from "./components/ContactDeveloper/ContactDeveloper";
import { Helmet, HelmetProvider } from "react-helmet-async";
import ClipLoader from "react-spinners/ClipLoader";
import "./styles/global.scss";

import { IPwaData } from "./interfaces";
import { pwaMockData } from "./mockup/pwa";
import OneSignal from "react-onesignal";
import { CookieBanner } from "./components/CookieBanner";

type IDuckSayPWA = {
  pwaData?: IPwaData;
  testPwaDomain?: string;
  isMockPWA?: boolean;
  isPreview?: boolean;
  loading?: boolean;
};

const PWA: React.FC<IDuckSayPWA> = ( { pwaData: pwa, testPwaDomain, isPreview, loading } ) => {
  const [manifest, setManifest] = React.useState<any>();
  const [defferedPrompt, setDeferredPrompt] = React.useState<any>( null );
  const domain = window.location.hostname;
  const isOneSignalInnit = useRef( false );

  const initOneSignal = async() => {
    if( isPreview ) {
      return;
    }
    if( typeof window !== "undefined" ) {
      await OneSignal.init( {
        appId: pwa?.osId || "",
        notifyButton: {
          enable: false,
        },
        autoResubscribe: true,
        // Uncomment the below line to run on localhost. See: https://documentation.onesignal.com/docs/local-testing
        // allowLocalhostAsSecureOrigin: true,
      } );
      // OneSignal.Debug.setLogLevel("trace");
      // Request permission to send notifications
      await OneSignal.Notifications.requestPermission();
    }
  };

  useEffect( () => {
    if( pwa?.osId ) {
      void fetchManifest()
    }
  }, [pwa?.osId] );

  useEffect( () => {
    if( !isOneSignalInnit.current && pwa?.osId ) {
      isOneSignalInnit.current = true;
      void initOneSignal();
    }
  }, [isOneSignalInnit, pwa?.osId] );

  const fetchManifest = async() => {
    try {
      const requestDomain = testPwaDomain || domain;
      const response = await fetch( `https://${ requestDomain }/manifest.json` );
      if( !response.ok ) {
        throw new Error( "Error data" );
      }
      const data = await response.json();
      localStorage.setItem( "manifestStorage", JSON.stringify( data ) );
      setManifest( data );
    } catch( error ) {
      console.error( "Error:", error );
    }
  };

  useEffect( () => {
    const handleBeforeInstallPrompt = ( e: Event ) => {
      e.preventDefault();
      setDeferredPrompt( e );
    };

    window.addEventListener( "beforeinstallprompt", handleBeforeInstallPrompt );

    return () => {
      window.removeEventListener( "beforeinstallprompt", handleBeforeInstallPrompt );
    };
  }, [] );

  return (
    <HelmetProvider>
      { !isPreview ? (
        <Helmet>
          <title>{ manifest?.short_name || "Google Play" }</title>
          <meta name="viewport" content="width=device-width,initial-scale=1"/>
          <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>

          <meta name="theme-color" content={ manifest?.theme_color || "#FFFFFF" }/>

          <meta name="google"/>
          <meta name="Description" content={ manifest?.name || "Default Description" }/>

          <meta name="apple-mobile-web-app-title" content={ manifest?.name || "Google Play" }/>
          { pwa?.metaTags || null }
        </Helmet>
      ) : null }
      { !loading ? (
        <>
          <div className="container">
            { pwa && (
              <>
                <MainInfo
                  defferedPrompt={ defferedPrompt }
                  setDefferedPrompt={ setDeferredPrompt }
                  pwa={ pwa }
                  testPwaDomain={ testPwaDomain }
                />
                <div className="wrapper">
                  <div className="grid-container">
                    <div className="grid-item item-large">
                      <div className="pb-16">
                        <Vertical pwa={ pwa }/>
                      </div>
                      <div className="pb-16">
                        <Description pwa={ pwa }/>
                      </div>
                      <div className="pb-16">
                        <DataSecurity pwa={ pwa }/>
                      </div>
                      <div className="pb-16">
                        <Rating pwa={ pwa }/>
                      </div>
                    </div>
                    <div className="grid-item item-small">
                      <ContactDeveloper pwa={ pwa }/>
                    </div>
                  </div>
                </div>
              </>
            ) }
          </div>
          { pwa?.filesCookies ? <CookieBanner/> : null }</>
      ) : (
        <div className={ "loader-cover" }>
          <ClipLoader
            color={ "#0e77c2" }
            loading={ true }
            // cssOverride={override}
            size={ 150 }
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) }
    </HelmetProvider>
  )
};

export default PWA;
